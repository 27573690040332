.svgs {
  cursor: url("../assets/wand.png"), auto;
  display: flex;
  gap: 0.3rem;
  align-items: flex-end;
}

.svgs svg {
  transition: scale 200ms ease;
}

.svgs svg:hover {
  scale: 1.1;
}
