.main{
  padding: 2rem 1.125rem;
}
.headerBox {
  display: flex;
  flex-direction: column-reverse;

  gap: 1rem;
}
.mainImage {
  width: 200px;
}
.heading {
  margin-bottom: 1rem;
}
.heading span {
  color: var(--bg-color);
}

.description {
  font-size: 1.3rem;
  max-width: 22ch;
  margin-top: 1rem;
}

@media (min-width: 45em) {
  .main{
    padding: 3rem clamp(2rem, 6vw, 6rem);
  }
  .headerBox {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
  }
  .mainImage {
    width: 28vw;
  }
  .heading{
    font-size: 2.5rem; 
  }
  .description{
    font-size: 1.4rem; 
    max-width: 60ch; 
  }
}
