.featuredProjects {
  margin-top: 2rem;
}
.sectionTitle {
  font-size: 2.5rem;
  background: -webkit-linear-gradient(rgb(240, 106, 146), rgb(255, 216, 166));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

.cardContainer {
  display: grid;
  gap: 1rem;
}
.card {
  background-color: #fff;
  color: var(--bg-color);
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cardImage {
  width: 100%;
}

.bottomCard {
  padding: 1rem;
}

.title {
  font-weight: 700;
  font-size: 1.2rem;
}

.tags {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  gap: 1rem;
  margin-block: 0.5rem;
}

.tag {
  background: linear-gradient(125deg, #f06a93, rgb(255, 216, 166));
  border-radius: 20px;
  padding: 0.2rem 0.8rem;
  color: #fff;
}

.links {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.links a {
  cursor: pointer;
  text-decoration: none;
  color: var(--bg-color);
  display: flex;
  align-items: center;
  gap: 0.4rem;
  transition: all 300ms ease;
  width: 10rem;
}

.links a img {
  width: 24px;
}

.links a:hover {
  font-weight: 700;
}

.featureCard {
  background: linear-gradient(125deg, #f06a93, rgb(255, 216, 166));
  border-radius: 10px;
  display: grid;
  place-content: center;
  font-size: 3rem;
  padding: 2rem;
  line-height: 1em;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.featuredBlogs {
  margin-top: 3rem;
}

.blogTitle {
  font-size: 1.6rem;
  text-decoration: none;
}

.blogImage {
  height: 60%;
  width: 100%;
}

.socialSection {
  margin-block: 3rem;
}
.socialLinks {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.socialLink {
  display: flex;
  gap: 1rem;
  color: #fff;
  font-size: 1.3rem;
  align-items: center;
  text-decoration: none !important;
  box-shadow: inset 0 0 0 0 #ffffff;
  padding-inline: .5rem; 
  transition: color 400ms ease-in-out, box-shadow 400ms ease-in-out;
  border-radius: 5px; 
}

.socialLink:hover {
  box-shadow: inset 500px 0 0 #ffffff;
  color: #f06a93
}

.socialLink img {
  width: 24px;
}


@media (min-width: 700px) {
  .cardContainer {
    grid-template-columns: 1fr 1fr;
  }
  .socialLinks {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 1300px) {
  .cardContainer {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .featureCard {
    font-size: 4rem;
  }
}
